import React, { createContext,useState, useReducer, useEffect } from 'react';
import { GulliverReducer } from './reducer';

export const GulliverContext = createContext();

const GulliverContextProvider = ({children}) => {
  const [hasInit, setHasInit] = useState(false);
  const [gulliver, dispatch] = useReducer(GulliverReducer, []);
  useEffect(()=>{
    const localData = localStorage.getItem('gulliver');
    const localGulliver = localData ? JSON.parse(localData) : [];
    if(Array.isArray(localGulliver) && localGulliver.length){
      dispatch({
        type: 'UPDATE_LOCAL_DATA',
        value: localGulliver
      })
    }
    setHasInit(true);
  },[])
  useEffect(() => {
    if(hasInit){
      localStorage.setItem('gulliver', JSON.stringify(gulliver));
    }
  }, [gulliver]);
  return (
    <GulliverContext.Provider value={{ gulliver, dispatch }}>
      {children}
    </GulliverContext.Provider>
  );
}
 
export default GulliverContextProvider;