import React, { useState, useContext, useEffect } from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "@/components/Layout"
import Image from "@/components/Image"
import SouvenirCard from "@/components/SouvenirCard"
import { Tabs,MultiLineTabs, TextTab } from "@/components/Tabs"
import NoMatch from "@/components/NoMatch"

import GulliverContextProvider, { GulliverContext } from "@/contexts/Gulliver"
import useTranslation from "@/hooks/useTranslation"
import createNodeId from "@/utils/createNodeId"

const GulliverSouvenirPage = () => {
  const queryData = useStaticQuery(graphql`
    query {
      allGulliverYaml {
        nodes {
          name
          avatar {
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          souvenirs {
            name {
              en
              ja
              ko
              zh_hans
              zh_hant
            }
            icon {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            source
            palette
          }
          pirateSouvenirs {
            name {
              en
              ja
              ko
              zh_hans
              zh_hant
            }
            icon {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            source
            palette
          }
        }
      }
    }
  `)
  const t = useTranslation()
  const {
    souvenirs,
    pirateSouvenirs,
    ...GulliverInfo
  } = queryData.allGulliverYaml.nodes[0]

  const totalSouvenirs = [...souvenirs,...pirateSouvenirs]

  const souvenirsHasMarked = totalSouvenirs.map((souvenir) => ({
    ...souvenir,
    isCollected: false,
    id: createNodeId(souvenir.name.en, `acnh`),
  }))

  const [value, setValue] = useState(0)

  const [value2, setValue2] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleChange2 = (event, newValue) => {
    setValue2(newValue)
  }

  return (
    <Layout>
      <PageContainer>
        <div className="header">
          <Image
            className="img"
            src={GulliverInfo.avatar.childImageSharp.fluid}
          />
          <span>{t(GulliverInfo.name)}</span>
        </div>
        <MultiLineTabs value={value} onChange={handleChange}>
          <TextTab label="all" />
          <TextTab label="sailorSeries" />
          <TextTab label="pirateSeries" />
        </MultiLineTabs>
        <MultiLineTabs value={value2} onChange={handleChange2}>
          <TextTab label="all" />
          <TextTab label="notCollected" />
          <TextTab label="collected" />
        </MultiLineTabs>
        <GulliverContextProvider>
          <SouvenirCards souvenirs={souvenirsHasMarked} tabs={{ value, value2 }} />
        </GulliverContextProvider>
      </PageContainer>
    </Layout>
  )
}

export default GulliverSouvenirPage

const PageContainer = styled.div`
  padding: 0 32px;
  min-height: calc(100vh - 72px - 116px);
  @media (max-width: 450px) {
    padding: 0 20px;
  }
  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 14px 0 18px;
    .img {
      width: 150px;
      border-radius: 50%;
    }
    span {
      margin: 14px 0 18px;
    }
  }
`

const SouvenirCards = ({ souvenirs, tabs }) => {
  const { gulliver } = useContext(GulliverContext)
  
  const currentSouvenirs = React.useMemo(() => {
    const { value,value2 } = tabs

    const souvenirsHasMarked = souvenirs.map((souvenir) => {
      const souvenirHasCollected = gulliver.filter((id) => id === souvenir.id)
      return {
        ...souvenir,
        isCollected: souvenirHasCollected.length ? true : false,
      }
    })

    let souvenirsArr = [...souvenirsHasMarked]

    if (value === 1) {
      souvenirsArr = souvenirsArr.filter((souvenir) => souvenir.source==="Gulliver")
    } else if (value === 2) {
      souvenirsArr = souvenirsArr.filter((souvenir) => souvenir.source==="Gullivarrr")
    }

    if (value2 === 1) {
      souvenirsArr = souvenirsArr.filter((souvenir) => !souvenir.isCollected)
    } else if (value2 === 2) {
      souvenirsArr = souvenirsArr.filter((souvenir) => souvenir.isCollected)
    }

    return souvenirsArr
  }, [gulliver, souvenirs, tabs])

  return (
    <StyledSouvenirCards>
      {currentSouvenirs.length ? (
        <div className="souvenir_cards">
          {currentSouvenirs.map((souvenir) => (
            <SouvenirCard souvenir={souvenir} key={souvenir.name.en} />
          ))}
        </div>
      ) : (
        <NoMatch />
      )}
    </StyledSouvenirCards>
  )
}

const StyledSouvenirCards = styled.div`
  .souvenir_cards {
    display: flex;
    flex-wrap: wrap;
    padding: 10px 15px;
    @media (max-width: 450px) {
      padding: 10px 0;
    }
  }
`
